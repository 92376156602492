import React from 'react'

import Layout from '../components/layout'
import logo from '../images/logo_labdupre.svg'
import facebook from '../images/facebook.png'
import instagram from '../images/instagram.png'
import gmaps from '../images/gmaps.png'
import trip from '../images/trip.png'
import whatsapp from '../images/whatsapp.png'

const IndexPage = () => (
  <Layout>
    <section className="sec2">
      <img
        className="logo"
        src={logo}
        alt="Laboratori Duprè - Pasticceria Parma"
      />
    </section>

    <section className="sec1">
      <h1 className="win">Laboratori Duprè</h1>
    </section>

    <section className="sec3">
      <ul className="contacts">
        <li>
          <a
            href="https://www.facebook.com/laboratoridupre"
            title="Seguici su Facebook"
          >
            <img src={facebook} alt="Seguici su Facebook" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/laboratoridupre/"
            title="Seguici su Instagram"
          >
            <img src={instagram} alt="Seguici su Instagram" />
          </a>
        </li>
        <li>
          <a href="https://g.co/kgs/HxkJLGG" title="Dove Siamo">
            <img src={gmaps} alt="Dove Siamo" />
          </a>
        </li>
        <li>
          <a href="https://wa.me/393770242567" title="Contattaci su WhatsApp">
            <img src={whatsapp} alt="Contattaci su Whatsapp!" />
          </a>
        </li>
      </ul>
      <p className="footer">
        <strong>Laboratori Duprè Parma</strong>
        <br />
        Piazzale Lubiana, 13/a - 43123 Parma (PR)
        <br />
        Tel. <a href="tel:+3905211811699">0521 181 1699</a> - Cel.{' '}
        <a href="tel:+393770242567">+39 377 024 2567</a>
        <br />
        <a href="mailto:info@laboratoridupre.it">info@laboratoridupre.it</a>
      </p>
    </section>
  </Layout>
)

export default IndexPage
